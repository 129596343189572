/**
 * @generated SignedSource<<caeeba989474aea0afafb7a1d82ca6aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_query" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_query">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_query";
};
export type ReportExportButton_ReportingPlatform_query$key = {
  readonly " $data"?: ReportExportButton_ReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportExportButton_ReportingPlatform_query",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_query"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0131d28740264c7e6c2ed9236fc8410e";

export default node;
