/* eslint-disable no-case-declarations */
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { useCombobox } from 'downshift';
import React, { useState, useRef, useEffect } from 'react';

import {
  Box,
  Icon,
  List,
  Text,
  DisplayNamed,
  MultiSelectItemsList,
  ArrowWrapper,
  MultiSelectTextInput,
  MultiSelectWrapper,
  PublicSelectGroup,
  PublicSelectItem,
  TagGroup,
  useItems,
  useRecursiveMatch,
  MenuItems,
  MenuItem,
  MenuItemValueType,
  getItemPropsType,
  MultiSelectProps,
  Menu as PicnicSelectMenu,
  styled,
} from '@attentive/picnic';

const BLUR_TIME_OFFSET = 30;
const PU_INPUT_MIN_WIDTH = 200;
const PU_MENU_MIN_HEIGHT = 200;
const PU_MENU_CONTENT_OFFSET = 8;
const PU_MENU_MAX_HEIGHT = 300;
const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  zIndex: '$layer4',
});
const itemToString = (item: MenuItem | null) => item?.label ?? '';

// Please try not to use this, this is a quickish fix for a larger problem
const ModalSafeMultiselectComponent = ({
  placeholder = 'Search',
  children = [],
  css = {},
  value,
  size = 'medium',
  state = 'normal',
  disabled = false,
  onChange,
  maxTokens = 10,
  errors,
}: MultiSelectProps) => {
  const [term, setTerm] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const allowBlur = useRef<boolean>(true);
  const allowBlurTimeoutId = useRef<number>();

  const handleChange = onChange as (value: MenuItemValueType[]) => void;

  const items = useItems(children) as MenuItems;
  const [selectedItems, setSelectedItems] = useState(
    ((value || []) as MenuItemValueType[])
      .map((val) => items.find((item) => item.value === val))
      .filter((item) => item !== undefined) as MenuItem[]
  );

  useEffect(() => {
    setSelectedItems(
      ((value || []) as MenuItemValueType[])
        .map((val) => items.find((item) => item.value === val))
        .filter((item) => item !== undefined) as MenuItem[]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => clearTimeout(allowBlurTimeoutId?.current);
  }, []);

  const filteredItems = useRecursiveMatch(items, term);
  const itemsLookup = filteredItems.reduce(
    (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
      prev[curr.value] = {
        item: curr,
        index,
      };
      return prev;
    },
    {}
  );
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    openMenu,
    closeMenu,
  } = useCombobox({
    itemToString,
    inputValue: term,
    items: filteredItems,
    onStateChange: (comboboxState) => {
      const { inputValue, type, selectedItem } = comboboxState;
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setTerm(inputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          allowBlur.current = false;
          clearTimeout(allowBlurTimeoutId.current);
          setTimeout(() => (allowBlur.current = true), BLUR_TIME_OFFSET);
          if (selectedItem && !selectedItem.disabled) {
            let newValues;
            if (selectedItems.find((s) => s.value === selectedItem.value)) {
              newValues = selectedItems
                .filter((s) => s.value !== selectedItem.value)
                .map((s) => s.value);
              handleChange(newValues);
              selectItem({ label: '', value: '' });
            } else {
              newValues = [...selectedItems.map((s) => s.value), selectedItem.value];
              handleChange(newValues);
              selectItem({ label: '', value: '' });
            }
            setTerm('');
          }
          break;
        default:
          break;
      }
    },
    // This keeps the select open on item click so that we can choose multiple options
    stateReducer: (comboboxState, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          const isOpenNew = allowBlur.current ? actionAndChanges.changes.isOpen : true;
          return { ...actionAndChanges.changes, isOpen: isOpenNew };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...actionAndChanges.changes,
            isOpen: true,
            highlightedIndex: comboboxState.highlightedIndex,
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  const focusOnInput = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.focus();
  };

  const toggleMenu = () => {
    if (disabled) return;
    return isOpen ? closeMenu() : openMenu();
  };

  const availableHeightForMenu =
    typeof window === 'undefined'
      ? 0
      : window.innerHeight -
        // get the size of element to its relative position
        (wrapperRef?.current?.getBoundingClientRect().bottom || 0) -
        //to match the offset at top from the select button
        PU_MENU_CONTENT_OFFSET * 2 -
        // a little hacky to prevent collisions
        20;

  const wrapperWidth = (wrapperRef.current?.offsetWidth || 0) as number;
  return (
    <PopoverPrimitive.Root open={isOpen}>
      <PopoverPrimitive.Trigger asChild>
        <MultiSelectWrapper
          ref={wrapperRef}
          onClick={focusOnInput}
          css={css}
          size={size}
          state={state}
          disabled={disabled}
        >
          <TagGroup
            wrapperWidth={wrapperWidth}
            selectedItems={selectedItems}
            removeSelectedItem={(removedValue) => {
              const newValues = value.filter((v) => v !== removedValue.value);
              handleChange(newValues);
            }}
            maxTokens={maxTokens}
            disabled={disabled}
            errors={errors}
          />

          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              minWidth: PU_INPUT_MIN_WIDTH,
            }}
            {...getComboboxProps()}
          >
            <MultiSelectTextInput
              placeholder={placeholder}
              data-testid="modal-safe-multi-select-input"
              disabled={disabled}
              size={size === 'medium' ? 'normal' : 'small'}
              css={{ width: '100%', ...css }}
              {...getInputProps({ ref: inputRef, onClick: toggleMenu })}
            />
            <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
              <Icon
                css={{ display: 'block', cursor: disabled ? 'not-allowed' : 'pointer' }}
                name="ChevronDown"
                size="small"
                description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
                onClick={toggleMenu}
              />
            </ArrowWrapper>
          </Box>
        </MultiSelectWrapper>
      </PopoverPrimitive.Trigger>

      <PopoverPrimitive.Portal>
        <StyledPopoverContent
          side="bottom"
          sideOffset={PU_MENU_CONTENT_OFFSET}
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}
          data-testid="modal-safe-multi-select-list"
        >
          <PicnicSelectMenu
            {...getMenuProps({}, { suppressRefError: true })}
            css={{
              position: 'static',
              display: 'flex',
              flexDirection: 'column',
              width: wrapperRef?.current?.clientWidth,
              // Restrict the height of the menu to the available space on
              // the screen within a given min/max range.
              maxHeight: Math.max(
                PU_MENU_MIN_HEIGHT,
                Math.min(availableHeightForMenu, PU_MENU_MAX_HEIGHT)
              ),
            }}
          >
            {isOpen && (
              <>
                {filteredItems.length > 0 && (
                  <List variant="unstyled">
                    <MultiSelectItemsList
                      highlightedIndex={highlightedIndex}
                      value={value}
                      getItemProps={getItemProps as getItemPropsType}
                      itemsLookup={itemsLookup}
                      size={size}
                    >
                      {children}
                    </MultiSelectItemsList>
                  </List>
                )}
                {filteredItems.length === 0 && (
                  <Box css={{ p: '$space2' }}>
                    <Text>No results</Text>
                  </Box>
                )}
              </>
            )}
          </PicnicSelectMenu>
        </StyledPopoverContent>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};

type ComponentType = typeof ModalSafeMultiselectComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Group: typeof PublicSelectGroup;
  Item: typeof PublicSelectItem;
}

const ModalSafeMultiselect = ModalSafeMultiselectComponent as CompositeComponent;
ModalSafeMultiselect.Group = PublicSelectGroup;
ModalSafeMultiselect.Item = PublicSelectItem;

ModalSafeMultiselect.displayName = 'ModalSafeMultiselect';
ModalSafeMultiselect.Item.displayName = 'ModalSafeMultiselect.Item';
ModalSafeMultiselect.Group.displayName = 'ModalSafeMultiselect.Group';

export { ModalSafeMultiselect };
