// dimensions
export const SEGMENT_DIMENSION_ID = 'segment_name_aggregate_group';
export const SEGMENT_DESCRIPTION_DIMENSION_ID = 'segment_description_aggregate_group';
export const HAS_AUDIENCE_AI_DIMENSION_ID = 'has_audience_ai';
export const CHANNEL_DIMENSION_IDS = ['channel', 'message_channel'];

// metrics
export const SUPPRESSED_SENDS_METRIC_ID = 'campaign_suppressed_sends_aggregate_group';
export const AI_JOURNEYS_COST_METRIC_ID = 'aij_click_cost';
export const TEXT_MESSAGE_RECEIVED_COST_METRIC_ID = 'text_message_received_cost';
export const TEXT_MESSAGE_TOTAL_COST_METRIC_ID = 'text_message_total_cost';
export const AI_JOURNEYS_ORDERED_SUMMARY_METRICS = [
  'text_message_campaign_cost',
  'text_message_automated_send_cost',
  'text_message_carrier_fees',
  'aij_click_cost',
  'text_message_total_cost',
];

// reports
export const AI_JOURNEY_PERFORMANCE_REPORT_ID = 'ai_journeys_performance';
export const DAILY_MESSAGE_COST_REPORT_ID = 'daily_message_cost';
export const SEGMENTS_DOMAIN_CAMPAIGN_REPORT_IDS = [
  'campaign_performance_by_segment',
  'campaign_aggregate_performance_by_segment',
  'campaign_performance_by_segment_email_only',
  'campaign_aggregate_performance_by_segment_email_only',
];
export const IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_ID =
  'identity_ai_new_subscriber_performance';
export const IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_EMAIL_ONLY_ID =
  'identity_ai_new_subscriber_performance_email_only';

export const IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_IDS = [
  IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_ID,
  IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_EMAIL_ONLY_ID,
];

export const IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_ID = 'identity_ai_abandonment_performance';
export const IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_EMAIL_ONLY_ID =
  'identity_ai_abandonment_performance_email_only';
export const IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_IDS = [
  IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_ID,
  IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_EMAIL_ONLY_ID,
];
export const NEW_REPORTS = [
  ...SEGMENTS_DOMAIN_CAMPAIGN_REPORT_IDS,
  ...IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_IDS,
  ...IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_IDS,
];

// report classes
export const IDENTITY_AI_REPORT_CLASS_NAME = 'Identity AI Class';
export const AI_PRO_SUBSCRIBER_GROWTH_REPORT_CLASS_NAME = 'AI Pro Subscriber Growth Class';

// report domains
export const AI_PRO_DOMAIN_ID = 'ai_pro';
export const CAMPAIGNS_DOMAIN_ID = 'campaigns';
export const SEGMENTED_CAMPAIGNS_DOMAIN_ID = 'campaigns_aggregate_group';
export const BILLING_DOMAIN_ID = 'billing';
export const CONCIERGE_DOMAIN_ID = 'concierge';
export const INTEGRATIONS_DOMAIN_ID = 'integrations';
export const EMAIL_ONLY_REPORT_DOMAINS = [
  'ai_pro_email_only',
  'billing_email_only',
  'campaigns_email_only',
  'campaigns_aggregate_group_email_only',
  'journeys_email_only',
  'program_email_only',
  'subscribers_and_sign_up_units_email_only',
  'segments_email_only',
];
export const STANDARD_REPORT_DOMAINS = [
  'ai_pro',
  'billing',
  'campaigns',
  'campaigns_aggregate_group',
  'concierge', // no email only equivalent, should hide entirely
  'journeys',
  'program',
  'subscribers_and_sign_up_units',
  'segments',
];

export const EMAIL_ONLY_INCOMPATIBLE_TRIGGER_NAMES = ['Texted a keyword', 'Signed up for texts'];
export const EMAIL_ONLY_INCOMPATIBLE_CREATIVE_TYPES = ['TEXT_TO_JOIN'];
