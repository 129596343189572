import _useShouldHideCurrencyMetrics_ReportingPlatformLib_report from "./__generated__/useShouldHideCurrencyMetrics_ReportingPlatformLib_report.graphql";
import _useShouldHideCurrencyMetrics_ReportingPlatformLib_company from "./__generated__/useShouldHideCurrencyMetrics_ReportingPlatformLib_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { isReportStringFilter } from '../services';
import { isBillableReport } from '../utils/billableReportUtils';
import CompanyFragment from './__generated__/useShouldHideCurrencyMetrics_ReportingPlatformLib_company.graphql';
import ReportFragment from './__generated__/useShouldHideCurrencyMetrics_ReportingPlatformLib_report.graphql';
_useShouldHideCurrencyMetrics_ReportingPlatformLib_company;
_useShouldHideCurrencyMetrics_ReportingPlatformLib_report;
export const useShouldHideCurrencyMetrics = _ref => {
  var _reportData$reportCla, _reportData$reportCla2;

  let {
    reportRef = null,
    companyRef = null,
    currentReportFilters,
    currentGroupings
  } = _ref;
  const isReportingRegionCurrencyOverrideEnabled = useCompanyFeatureFlag('ENABLE_REPORTING_REGION_CURRENCY_OVERRIDE');
  const reportData = useFragment(ReportFragment, reportRef);
  const companyData = useFragment(CompanyFragment, companyRef);

  if (isReportingRegionCurrencyOverrideEnabled) {
    return {
      shouldHideCurrencyMetrics: false,
      doesReportHavePossibleMismatchedCurrencies: false
    };
  }

  if (!(reportData !== null && reportData !== void 0 && reportData.reportClass)) {
    return {
      shouldHideCurrencyMetrics: false,
      doesReportHavePossibleMismatchedCurrencies: false
    };
  }

  const isMultiRegion = ((companyData === null || companyData === void 0 ? void 0 : companyData.regions) || []).length > 1;
  const hasCurrencyMetrics = reportData.reportClass.metricDefinitions.some(metricDefinition => metricDefinition.dataType === 'METRIC_DATA_TYPE_CURRENCY');
  const isNotCostReport = !isBillableReport(reportData === null || reportData === void 0 ? void 0 : (_reportData$reportCla = reportData.reportClass) === null || _reportData$reportCla === void 0 ? void 0 : _reportData$reportCla.name);
  const canFilterByRegion = reportData === null || reportData === void 0 ? void 0 : (_reportData$reportCla2 = reportData.reportClass) === null || _reportData$reportCla2 === void 0 ? void 0 : _reportData$reportCla2.filterDimensions.some(filterDimension => filterDimension.dimension.dimensionId === 'region');
  const hasSingleRegionFilter = currentReportFilters.some(filter => {
    if (!(isReportStringFilter(filter) && filter.dimensionId === 'region')) {
      return false;
    }

    const hasSingleRegionValueInList = filter.values.length === 1;
    const isInOperator = filter.filterOperator === 'FILTER_OPERATOR_IN';
    return hasSingleRegionValueInList && isInOperator;
  });
  const hasRegionGrouping = currentGroupings === null || currentGroupings === void 0 ? void 0 : currentGroupings.some(grouping => grouping.dimensionId === 'region');
  const doesReportHavePossibleMismatchedCurrencies = isMultiRegion && hasCurrencyMetrics && isNotCostReport;
  const isRegionSelected = hasSingleRegionFilter || hasRegionGrouping;
  return {
    shouldHideCurrencyMetrics: doesReportHavePossibleMismatchedCurrencies && canFilterByRegion && !isRegionSelected,
    doesReportHavePossibleMismatchedCurrencies,
    isRegionSelected,
    canFilterByRegion
  };
};