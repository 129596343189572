/**
 * @generated SignedSource<<ef1e303cbdf8eca7ec44a56d3e69d54b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShouldHideAiJourneysCost_ReportingPlatformLib_company$data = {
  readonly aiJourneysCostMetrics: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly " $fragmentType": "useShouldHideAiJourneysCost_ReportingPlatformLib_company";
};
export type useShouldHideAiJourneysCost_ReportingPlatformLib_company$key = {
  readonly " $data"?: useShouldHideAiJourneysCost_ReportingPlatformLib_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldHideAiJourneysCost_ReportingPlatformLib_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShouldHideAiJourneysCost_ReportingPlatformLib_company",
  "selections": [
    {
      "alias": "aiJourneysCostMetrics",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Literal",
          "name": "metricIds",
          "value": [
            "aij_click_cost"
          ]
        }
      ],
      "concreteType": "Metric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aggregateValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "0cb5f9854b12ece6e2ad4c8eccf81777";

export default node;
