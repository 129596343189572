import { MetricConnotation, MetricDataType } from '@attentive/data/types';

import { MetricCardConnotation, MetricCardDataType } from './types';

export const mapMetricDataType = ({
  dataType,
}: {
  dataType?: MetricDataType;
}): MetricCardDataType => {
  if (dataType === MetricDataType.MetricDataTypePercent) return 'percent';
  if (dataType === MetricDataType.MetricDataTypeCurrency) return 'currency';

  return 'number';
};

export const mapMetricConnotation = ({
  connotation,
}: {
  connotation?: MetricConnotation;
}): MetricCardConnotation => {
  if (connotation === MetricConnotation.MetricConnotationPositive) return 'positive';
  if (connotation === MetricConnotation.MetricConnotationNegative) return 'negative';

  return 'neutral';
};
