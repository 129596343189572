import { faker } from '@faker-js/faker';
import { DecoratorFn } from '@storybook/react';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw';
import React from 'react';

import { parseMSWHeaders } from '@attentive/mock-data';

import { MockReportFactory, Report } from '../../services';

import { ReportDomainsProvider } from './ReportDomainsContext';

import {
  ReportDomainsContextReportingPlatformQuery$rawResponse,
  ReportDomainsContextReportingPlatformQuery$variables,
} from './__generated__/ReportDomainsContextReportingPlatformQuery.graphql';

export interface ReportDomainsContextMockMswHeaders {
  status?: number;
  reports?: Report[];
  aiProSubscriptionStatusType?: string;
  identityAiEatSubscribes?: number;
  featureFlags?: string[];
}

const reportDomainsGqlResponseResolver: ResponseResolver<
  GraphQLRequest<ReportDomainsContextReportingPlatformQuery$variables>,
  GraphQLContext<ReportDomainsContextReportingPlatformQuery$rawResponse>
> = async (req, res, ctx) => {
  const {
    reports: reportsArg,
    aiProSubscriptionStatusType,
    identityAiEatSubscribes,
    featureFlags,
  } = parseMSWHeaders<ReportDomainsContextMockMswHeaders>(req.headers);

  const reports =
    reportsArg !== undefined
      ? reportsArg
      : MockReportFactory.createList({
          numReports: faker.datatype.number({ min: 10, max: 30 }),
        });

  const response: ReportDomainsContextReportingPlatformQuery$rawResponse = {
    company: {
      __typename: 'Company',
      id: 'company-1',
      reports: {
        edges: reports.map((report) => ({ node: report })),
      },
    },
    featureFlags: {
      edges: featureFlags?.map((flag) => ({
        node: {
          id: flag,
        },
      })) ?? [
        {
          node: {
            id: 'feature-flag-1',
          },
        },
      ],
    },
    aiProSubscriptionCompanyData: {
      id: 'company-1',
      __typename: 'Company',
      aiProSubscriptionStatus: {
        status: {
          status: aiProSubscriptionStatusType
            ? {
                __typename: aiProSubscriptionStatusType,
              }
            : null,
        },
      },
    },
    identityAiEatSubscribesData: {
      id: 'company-1',
      __typename: 'Company',
      identityAiEatSubscribes: {
        aggregateValues: [{ value: identityAiEatSubscribes ?? 10 }],
      },
    },
  };

  return res(ctx.data(response));
};
export const ReportDomainsContextReportingPlatformQueryMock = graphql.query<
  ReportDomainsContextReportingPlatformQuery$rawResponse,
  ReportDomainsContextReportingPlatformQuery$variables
>('ReportDomainsContextReportingPlatformQuery', reportDomainsGqlResponseResolver);

export const ReportDomainsContextStoryDecorator: DecoratorFn = (Story) => {
  return (
    <ReportDomainsProvider>
      <Story />
    </ReportDomainsProvider>
  );
};
