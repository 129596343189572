/**
 * @generated SignedSource<<123dd3761a358329fcda51e0d50728ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_company$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_company" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_company";
};
export type ReportExportButton_ReportingPlatform_company$key = {
  readonly " $data"?: ReportExportButton_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_company">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportExportButton_ReportingPlatform_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "9857cc9e5801f2608b34b6aee7fd7db7";

export default node;
