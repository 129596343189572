export interface LegacyDateDimension {
  date: string;
  value: number;
}

export interface LegacySourceDimension {
  name: string;
  total: number;
  percent: number;
  groupByDate?: LegacyDateDimension[];
}

export type LegacyMetricType = 'number' | 'currency' | 'percent';
export type LegacyMetricDataType = 'integer' | 'decimal';

export interface LegacyDataError {
  type: string;
  message: string;
}

export type LegacyMetricId =
  | 'daily_active_subscribers'
  | 'emails_collected_cumulative_total'
  | 'new_subscribers'
  | 'total_unsubscribes'
  | 'sms_unsubscribes'
  | 'emails_collected_total'
  | 'total_revenue_cumulative_total'
  | 'revenue_per_campaign_message_sent'
  | 'revenue_per_journey_message_sent'
  | 'total_revenue'
  | 'campaigns_revenue'
  | 'journeys_revenue'
  | 'total_volume'
  | 'campaigns_volume'
  | 'journeys_volume'
  | 'total_billable_spend'
  | 'campaigns_billable_spend'
  | 'journeys_billable_spend'
  | 'average_campaigns_cvr'
  | 'average_journeys_cvr'
  | 'total_revenue_with_email'
  | 'campaigns_revenue_with_email'
  | 'journeys_revenue_with_email'
  | 'total_volume_with_email'
  | 'campaigns_volume_with_email'
  | 'journeys_volume_with_email'
  | 'total_message_revenue_sms_client'
  | 'total_message_revenue_email_client'
  | 'dashboard_journey_message_revenue'
  | 'dashboard_campaign_message_revenue'
  | 'aij_click_cost';

export type LegacyMetricConnotation = 'negative' | 'positive' | 'neutral';

export interface LegacyMetric {
  id: LegacyMetricId;
  name: string;
  description?: string | null;
  type: LegacyMetricType;
  dataType: LegacyMetricDataType;
  connotation?: LegacyMetricConnotation;
  total: number;
  sources?: LegacySourceDimension[];
  groupByDate: LegacyDateDimension[];
  errors?: LegacyDataError[];
}

export type LegacyAllTimeMetric = Pick<LegacyMetric, 'id' | 'name' | 'total' | 'type' | 'dataType'>;

export interface LegacyMetricDomain {
  id: string;
  name: string;
  disclaimer?: string;
  allTime: LegacyAllTimeMetric[];
  metrics: LegacyMetric[];
  maxBoxesPerRow?: number;
}

export interface GetMetricsQueryResponse {
  domains: LegacyMetricDomain[];
}

export enum TimeGranularity {
  DAY = 'day',
  MONTH = 'month',
}
