import _useHideAIJCostGeneratedReport_ReportingPlatform_query from "./__generated__/useHideAIJCostGeneratedReport_ReportingPlatform_query.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useHasAIJourneysAccess } from '@attentive/ml-common';
import { AI_JOURNEYS_BILLABLE_SPEND_REPORT_NAME } from '../constants';
import QueryFragment from './__generated__/useHideAIJCostGeneratedReport_ReportingPlatform_query.graphql';
_useHideAIJCostGeneratedReport_ReportingPlatform_query;
export const useHideAIJCostGeneratedReport = queryRef => {
  const queryData = useFragment(QueryFragment, queryRef);
  const [hasAIJourneysAccess] = useHasAIJourneysAccess(queryData);
  const isAIJourneysCostEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_COST');
  return reportName => !(isAIJourneysCostEnabled && hasAIJourneysAccess) && reportName === AI_JOURNEYS_BILLABLE_SPEND_REPORT_NAME;
};