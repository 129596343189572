/**
 * @generated SignedSource<<04104de76ae9bb6070c834b629476988>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useVisibleMetrics_ReportingPlatform_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useShouldHideAiJourneysCost_ReportingPlatformLib_company" | "useShouldHideCurrencyMetrics_ReportingPlatformLib_company">;
  readonly " $fragmentType": "useVisibleMetrics_ReportingPlatform_company";
};
export type useVisibleMetrics_ReportingPlatform_company$key = {
  readonly " $data"?: useVisibleMetrics_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useVisibleMetrics_ReportingPlatform_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useShouldHideCurrencyMetrics_ReportingPlatformLib_company"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useShouldHideAiJourneysCost_ReportingPlatformLib_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "047b756a38fcc3548b372c0dbb32ab74";

export default node;
