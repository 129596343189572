/**
 * @generated SignedSource<<4124933ce84ec4022982b94aa7bd794d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_query">;
  readonly " $fragmentType": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_query";
};
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_query$key = {
  readonly " $data"?: useGenerateReportMetricsColumnHeaders_ReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateReportMetricsColumnHeaders_ReportingPlatform_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "058fb240fffa68949c81361c123e69ab";

export default node;
