import _useShopifyAttributionOptInStates_company from "./__generated__/useShopifyAttributionOptInStates_company.graphql";
import { differenceInHours } from 'date-fns';
import { useState, useEffect } from 'react';
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { deserializeGQLDateTime, graphql } from '@attentive/data';
import { logError } from '../utils';
import useShopifyAttributionOptInStates_company from './__generated__/useShopifyAttributionOptInStates_company.graphql';
_useShopifyAttributionOptInStates_company;
export let ShopifyAttributionOptInStatus;

(function (ShopifyAttributionOptInStatus) {
  ShopifyAttributionOptInStatus["INELIGIBLE"] = "INELIGIBLE";
  ShopifyAttributionOptInStatus["ELIGIBLE"] = "ELIGIBLE";
  ShopifyAttributionOptInStatus["REFRESHING"] = "REFRESHING";
  ShopifyAttributionOptInStatus["COMPLETED"] = "COMPLETED";
})(ShopifyAttributionOptInStatus || (ShopifyAttributionOptInStatus = {}));

const eligibleStatuses = ['NEW_REVENUE_MODEL_STATUS_ENABLED', 'NEW_REVENUE_MODEL_STATUS_CANDIDATE'];
const STORAGE_KEY = 'shopifyAttributionOptInFlowSuccessDismissed';
export const useShopifyAttributionOptInStates = _ref => {
  let {
    companyRef
  } = _ref;
  const company = useFragment(useShopifyAttributionOptInStates_company, companyRef);
  const {
    attributionSettings
  } = company;
  const {
    newRevenueModel
  } = attributionSettings || {};
  const {
    optInTimestamp,
    status
  } = newRevenueModel || {};
  const [isSuccessDismissed, setIsSuccessDismissed] = useState(false);
  const upRevenueModelCandidate = useCompanyFeatureFlag('UP_REVENUE_MODEL_CANDIDATE');
  const enableShopifyAttributionOptInFlow = useCompanyFeatureFlag('ENABLE_SHOPIFY_ATTRIBUTION_OPT_IN_FLOW');
  useEffect(() => {
    const dismissalStatus = localStorage.getItem(STORAGE_KEY);
    setIsSuccessDismissed(dismissalStatus === 'true');
  }, []);

  const setSuccessDismissed = isDismissed => {
    localStorage.setItem(STORAGE_KEY, isDismissed.toString());
    setIsSuccessDismissed(isDismissed);
  };

  const unsupportedStatus = !status || !eligibleStatuses.includes(status);
  const showBellIconNotification = !!status && eligibleStatuses.includes(status) && !isSuccessDismissed;

  if (unsupportedStatus || !upRevenueModelCandidate || !enableShopifyAttributionOptInFlow) {
    return {
      status: ShopifyAttributionOptInStatus.INELIGIBLE,
      showBellIconNotification,
      isSuccessDismissed,
      setSuccessDismissed
    };
  }

  if (status === 'NEW_REVENUE_MODEL_STATUS_CANDIDATE') {
    return {
      status: ShopifyAttributionOptInStatus.ELIGIBLE,
      showBellIconNotification,
      isSuccessDismissed,
      setSuccessDismissed
    };
  }

  if (status === 'NEW_REVENUE_MODEL_STATUS_ENABLED') {
    if (!optInTimestamp) {
      logError(new Error('ShopifyAttributionOptInStatus: optInTimestamp is null with status ENABLED'));
      return {
        status: ShopifyAttributionOptInStatus.INELIGIBLE,
        showBellIconNotification: false,
        isSuccessDismissed,
        setSuccessDismissed
      };
    }

    const optInDatetime = new Date(deserializeGQLDateTime(optInTimestamp));
    const now = new Date(); // If the opt-in timestamp is more than 24 hours ago, we consider it completed

    if (differenceInHours(now, optInDatetime) >= 24) {
      return {
        status: ShopifyAttributionOptInStatus.COMPLETED,
        showBellIconNotification,
        isSuccessDismissed,
        setSuccessDismissed
      };
    }

    return {
      status: ShopifyAttributionOptInStatus.REFRESHING,
      showBellIconNotification,
      isSuccessDismissed,
      setSuccessDismissed
    };
  } // Fallback


  return {
    status: ShopifyAttributionOptInStatus.INELIGIBLE,
    showBellIconNotification,
    isSuccessDismissed,
    setSuccessDismissed
  };
};