/**
 * @generated SignedSource<<dab974115722b271a9394c8c7d3c0991>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricRevenueByCurrenciesReportingPlatform_company$data = {
  readonly country: string;
  readonly " $fragmentSpreads": FragmentRefs<"isCompanyMultiRegion_AnalyticsUI_company" | "useMetricRevenueByCurrencies_company">;
  readonly " $fragmentType": "MetricRevenueByCurrenciesReportingPlatform_company";
};
export type MetricRevenueByCurrenciesReportingPlatform_company$key = {
  readonly " $data"?: MetricRevenueByCurrenciesReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricRevenueByCurrenciesReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesGroupings"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesMetricId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricRevenueByCurrenciesReportingPlatform_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "isCompanyMultiRegion_AnalyticsUI_company",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "revenueByCurrenciesFilters",
          "variableName": "revenueByCurrenciesFilters"
        },
        {
          "kind": "Variable",
          "name": "revenueByCurrenciesGroupings",
          "variableName": "revenueByCurrenciesGroupings"
        },
        {
          "kind": "Variable",
          "name": "revenueByCurrenciesMetricId",
          "variableName": "revenueByCurrenciesMetricId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useMetricRevenueByCurrencies_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "736196d71723a2e45f0d2ee754242f53";

export default node;
