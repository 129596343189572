import _useShouldHideAiJourneysCost_ReportingPlatformLib_query from "./__generated__/useShouldHideAiJourneysCost_ReportingPlatformLib_query.graphql";
import _useShouldHideAiJourneysCost_ReportingPlatformLib_company from "./__generated__/useShouldHideAiJourneysCost_ReportingPlatformLib_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useHasAIJourneysAccess } from '@attentive/ml-common/src/aiJourneys';
import { extractSingleMetricAggregateValue } from '../utils';
import useShouldHideAiJourneysCost_ReportingPlatformLib_company from './__generated__/useShouldHideAiJourneysCost_ReportingPlatformLib_company.graphql';
import useShouldHideAiJourneysCost_ReportingPlatformLib_query from './__generated__/useShouldHideAiJourneysCost_ReportingPlatformLib_query.graphql';
_useShouldHideAiJourneysCost_ReportingPlatformLib_company;
_useShouldHideAiJourneysCost_ReportingPlatformLib_query;
export const useShouldHideAiJourneysCost = (companyRef, queryRef) => {
  const isAIJCostEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_COST');
  const company = useFragment(useShouldHideAiJourneysCost_ReportingPlatformLib_company, companyRef);
  const queryData = useFragment(useShouldHideAiJourneysCost_ReportingPlatformLib_query, queryRef);
  const [hasAIJourneysAccess] = useHasAIJourneysAccess(queryData);
  const aiJourneysCost = extractSingleMetricAggregateValue(company === null || company === void 0 ? void 0 : company.aiJourneysCostMetrics);
  const aiJourneysCostIsDisabled = !isAIJCostEnabled;
  const doesNotHaveAiJourneysAccess = !hasAIJourneysAccess;
  const aiJourneysCostIsZero = aiJourneysCost === 0;
  return doesNotHaveAiJourneysAccess || aiJourneysCostIsDisabled || aiJourneysCostIsZero;
};