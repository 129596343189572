import React, { useContext } from 'react';

import { CurrencyCode, Locale } from '@attentive/locale-utils';
import {
  Box,
  Card,
  compositeComponent,
  ContainedLabel,
  Heading,
  PicnicCss,
  Text,
  Tooltip,
} from '@attentive/picnic';

import { CoreReportingComponent } from '../../types';
import {
  calculatePercentChange,
  displayPercentChange,
  formatDisplayValue,
  getPillVariant,
} from '../../utils';

import { MetricCardConnotation, MetricCardDataType } from './types';

const HEADER_TOOLTIP_CSS = {
  width: 'fit-content',
  textDecoration: 'underline dashed',
  textDecorationThickness: '2px',
  textUnderlineOffset: '2px',
};

type MetricCardContextType = {
  size: 'medium' | 'small';
  value?: number | null;
  comparisonValue?: number | null;
  metricId?: string;
  name?: string;
  dataType?: MetricCardDataType;
  locale?: Locale;
  currencyCode?: CurrencyCode;
};

const MetricCardContext = React.createContext<MetricCardContextType | undefined>(undefined);

const useMetricCardContext = () => {
  const context = useContext(MetricCardContext);

  if (!context) {
    throw new Error('useMetricCardContext must be used within a MetricCardContextProvider');
  }

  return context;
};

const Header = ({
  description,
  children,
  'data-analytics-id': dataAnalyticsId,
}: {
  description?: string;
  children?: React.ReactNode;
} & CoreReportingComponent) => {
  const { size, name } = useMetricCardContext();
  const isMedium = size === 'medium';
  const displayName = name || children;
  const css = description ? HEADER_TOOLTIP_CSS : {};

  if (description) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          {isMedium ? (
            <Heading variant="sm" css={css} data-analytics-id={dataAnalyticsId}>
              {displayName}
            </Heading>
          ) : (
            <Text
              variant="micro"
              css={{ fontWeight: '$bold', ...css }}
              data-analytics-id={dataAnalyticsId}
            >
              {displayName}
            </Text>
          )}
        </Tooltip.Trigger>
        <Tooltip.Content>{description}</Tooltip.Content>
      </Tooltip>
    );
  }

  return isMedium ? (
    <Heading variant="sm" css={css} data-analytics-id={dataAnalyticsId}>
      {displayName}
    </Heading>
  ) : (
    <Text variant="micro" css={{ fontWeight: '$bold', ...css }} data-analytics-id={dataAnalyticsId}>
      {displayName}
    </Text>
  );
};

const Value = ({
  children,
  css,
  'data-analytics-id': dataAnalyticsId,
}: {
  children?: React.ReactNode;
  css?: PicnicCss;
} & CoreReportingComponent) => {
  const {
    size,
    metricId,
    value: rawValue,
    name,
    dataType,
    locale,
    currencyCode,
  } = useMetricCardContext();

  const displayValue = formatDisplayValue({
    metricId,
    value: rawValue,
    name,
    dataType,
    locale,
    currencyCode,
  });

  const variant = size === 'medium' ? 'lg' : 'sm';

  return (
    <Heading variant={variant} css={css} data-analytics-id={dataAnalyticsId}>
      {children || displayValue}
    </Heading>
  );
};

const Pill = ({
  variant,
  children,
  css,
  'data-analytics-id': dataAnalyticsId,
}: {
  variant?: 'success' | 'critical' | 'neutral';
  children?: React.ReactNode;
  css?: PicnicCss;
} & CoreReportingComponent) => {
  return (
    <ContainedLabel
      variant={variant}
      css={{ height: '$size4', alignSelf: 'center', ...css }}
      data-analytics-id={dataAnalyticsId}
    >
      {children}
    </ContainedLabel>
  );
};

const Section = ({
  children,
  css,
  'data-analytics-id': dataAnalyticsId,
}: {
  children?: React.ReactNode;
  css?: PicnicCss;
} & CoreReportingComponent) => {
  const { size } = useMetricCardContext();

  return (
    <Box
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        columnGap: size === 'medium' ? '$space3' : '$space2',
        ...css,
      }}
      data-analytics-id={dataAnalyticsId}
    >
      {children}
    </Box>
  );
};

const PercentChange = ({
  connotation,
  error,
  'data-analytics-id': dataAnalyticsId,
}: {
  connotation: MetricCardConnotation;
  error?: string;
} & CoreReportingComponent) => {
  const { value, comparisonValue, locale } = useMetricCardContext();
  const percentChange = calculatePercentChange({ value, comparisonValue });

  if (percentChange === null) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <Pill variant="neutral">-%</Pill>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <Text>{error}</Text>
        </Tooltip.Content>
      </Tooltip>
    );
  }

  const displayValue = displayPercentChange({ percentChange, locale });
  const variant = getPillVariant({ percentChange, connotation });

  return (
    <Pill variant={variant} data-analytics-id={dataAnalyticsId}>
      {displayValue}
    </Pill>
  );
};

const Subtext = ({
  children,
  css,
  'data-analytics-id': dataAnalyticsId,
}: {
  children?: React.ReactNode;
  css?: PicnicCss;
} & CoreReportingComponent) => {
  const { size } = useMetricCardContext();
  const variant = size === 'medium' ? 'caption' : 'micro';

  return (
    <Text variant={variant} color="subdued" css={css} data-analytics-id={dataAnalyticsId}>
      {children}
    </Text>
  );
};

const ComparisonValue = ({ 'data-analytics-id': dataAnalyticsId }: CoreReportingComponent) => {
  const { metricId, comparisonValue, name, dataType, locale, currencyCode } =
    useMetricCardContext();

  const displayValue = formatDisplayValue({
    metricId,
    value: comparisonValue,
    name,
    dataType,
    locale,
    currencyCode,
  });

  return <Subtext data-analytics-id={dataAnalyticsId}>{displayValue}</Subtext>;
};

const MetricCard = ({
  variant = 'card',
  size = 'medium',
  children,
  css,
  ...props
}: {
  variant?: 'card' | 'inline';
  size?: 'medium' | 'small';
  metricId?: string;
  value?: number | null;
  comparisonValue?: number | null;
  name?: string;
  dataType?: MetricCardDataType;
  locale?: Locale;
  currencyCode?: CurrencyCode;
  children?: React.ReactNode;
  css?: PicnicCss;
}) => {
  const Content = variant === 'card' ? Card : Box;

  return (
    <MetricCardContext.Provider value={{ size, ...props }}>
      <Content css={{ padding: '$space4', ...css }}>{children}</Content>
    </MetricCardContext.Provider>
  );
};

const Namespace = compositeComponent(MetricCard, {
  Header,
  Value,
  Pill,
  Section,
  Subtext,
  PercentChange,
  ComparisonValue,
});

export { Namespace as MetricCard };
