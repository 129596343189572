import _useReportDomains_AnalyticsUI_query from "./__generated__/useReportDomains_AnalyticsUI_query.graphql";
import _useReportDomains_AnalyticsUI_company from "./__generated__/useReportDomains_AnalyticsUI_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag, useRoles } from '@attentive/acore-utils';
import { graphql, Role } from '@attentive/data';
import { AI_JOURNEY_PERFORMANCE_REPORT_ID, AI_PRO_DOMAIN_ID, BILLING_DOMAIN_ID, CAMPAIGNS_DOMAIN_ID, CONCIERGE_DOMAIN_ID, EMAIL_ONLY_REPORT_DOMAINS, IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_IDS, IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_IDS, IDENTITY_AI_REPORT_CLASS_NAME, SEGMENTED_CAMPAIGNS_DOMAIN_ID, SEGMENTS_DOMAIN_CAMPAIGN_REPORT_IDS, STANDARD_REPORT_DOMAINS } from '../constants';
import { useAiProReportingStates } from './useAiProReportingStates';
import { useEmailOnlyReporting } from './useEmailOnlyReporting';
import { useHasAIJourneysPerformanceReportAccess } from './useHasAIJourneysPerformanceReportAccess';
import useReportDomains_AnalyticsUI_company from './__generated__/useReportDomains_AnalyticsUI_company.graphql';
import useReportDomains_AnalyticsUI_query from './__generated__/useReportDomains_AnalyticsUI_query.graphql';
_useReportDomains_AnalyticsUI_company;
_useReportDomains_AnalyticsUI_query;
export const useReportDomains = _ref => {
  var _company$reports, _reportsToRender;

  let {
    companyRef,
    queryRef,
    searchTerm = ''
  } = _ref;
  const company = useFragment(useReportDomains_AnalyticsUI_company, companyRef);
  const query = useFragment(useReportDomains_AnalyticsUI_query, queryRef);
  const {
    isIdentityDisabled,
    showAiProReportingFeatures,
    showAiProChurnState,
    showIdentityAiNewSubscriberPerformanceReport,
    showIdentityAiAbandonmentPerformanceReport
  } = useAiProReportingStates(query);
  const {
    hasAIJourneysPerformanceReportAccess
  } = useHasAIJourneysPerformanceReportAccess(query);
  const areSegmentedReportsEnabled = useCompanyFeatureFlag('ENABLE_REPORTS_LIBRARY_SEGMENTED_REPORTS');
  const roles = useRoles();
  const isManagedAccount = roles.has(Role.RoleClientManagedAccount);
  const {
    isEmailOnlyEnabled
  } = useEmailOnlyReporting();
  const allReports = [...((company === null || company === void 0 ? void 0 : (_company$reports = company.reports) === null || _company$reports === void 0 ? void 0 : _company$reports.edges) || [])];
  let reportsToRender = allReports; // if AIPro or identity is disabled, hide identity ai reports

  if (!showAiProReportingFeatures || isIdentityDisabled) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node$reportCl;

      return ((_report$node$reportCl = report.node.reportClass) === null || _report$node$reportCl === void 0 ? void 0 : _report$node$reportCl.name) !== IDENTITY_AI_REPORT_CLASS_NAME;
    });
  } // if AIPro is disabled, hide all ai reports


  if (!showAiProReportingFeatures) {
    reportsToRender = reportsToRender.filter(report => report.node.reportDomain.domainId !== AI_PRO_DOMAIN_ID);
  } // hide the identity ai new subscriber performance report if disabled


  if (!showIdentityAiNewSubscriberPerformanceReport) {
    reportsToRender = reportsToRender.filter(report => !IDENTITY_AI_NEW_SUBSCRIBER_PERFORMANCE_REPORT_IDS.includes(report.node.reportId));
  } // hide the identity ai abandonment performance report if disabled


  if (!showIdentityAiAbandonmentPerformanceReport) {
    reportsToRender = reportsToRender.filter(report => !IDENTITY_AI_ABANDONMENT_PERFORMANCE_REPORT_IDS.includes(report.node.reportId));
  } // if we don't have AI journey access, hide AI journey performance report


  if (!hasAIJourneysPerformanceReportAccess) {
    reportsToRender = reportsToRender.filter(report => report.node.reportId !== AI_JOURNEY_PERFORMANCE_REPORT_ID);
  } // if segmented reports are enabled, filter out non-segmented campaign reports


  if (areSegmentedReportsEnabled) {
    reportsToRender = reportsToRender.filter(report => report.node.reportDomain.domainId !== CAMPAIGNS_DOMAIN_ID);
  } // else filter out segmented campaign reports from multiple domains
  else {
    reportsToRender = reportsToRender.filter(report => report.node.reportDomain.domainId !== SEGMENTED_CAMPAIGNS_DOMAIN_ID);
    reportsToRender = reportsToRender.filter(report => !SEGMENTS_DOMAIN_CAMPAIGN_REPORT_IDS.includes(report.node.reportId));
  } // hide billable spend if managed account


  if (isManagedAccount) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node;

      return (report === null || report === void 0 ? void 0 : (_report$node = report.node) === null || _report$node === void 0 ? void 0 : _report$node.reportDomain.domainId) !== BILLING_DOMAIN_ID;
    });
  } // hide conceirge if managed account


  if (isManagedAccount) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node2;

      return (report === null || report === void 0 ? void 0 : (_report$node2 = report.node) === null || _report$node2 === void 0 ? void 0 : _report$node2.reportDomain.domainId) !== CONCIERGE_DOMAIN_ID;
    });
  } // if client is email only, show email only report domains otherwise standard report domains


  if (isEmailOnlyEnabled) {
    reportsToRender = reportsToRender.filter(report => !STANDARD_REPORT_DOMAINS.includes(report.node.reportDomain.domainId));
  } else {
    reportsToRender = reportsToRender.filter(report => !EMAIL_ONLY_REPORT_DOMAINS.includes(report.node.reportDomain.domainId));
  } // group reports by domain name


  const reportsByDomains = ((_reportsToRender = reportsToRender) === null || _reportsToRender === void 0 ? void 0 : _reportsToRender.reduce((prev, _ref2) => {
    let {
      node: report
    } = _ref2;

    // if domain hasn't been encountered yet, initialize to empty array
    if (!Object.keys(prev).includes(report.reportDomain.name)) {
      prev[report.reportDomain.name] = {
        description: report.reportDomain.description,
        title: report.reportDomain.name,
        reports: [],
        displayOrder: report.reportDomain.displayOrder,
        domainId: report.reportDomain.domainId
      };
    }

    prev[report.reportDomain.name].reports.push(report);
    return prev;
  }, {})) || {};
  const domains = Object.values(reportsByDomains); // Filter reports based on search term

  if (searchTerm) {
    domains.forEach(domain => {
      domain.reports = domain.reports.filter(report => {
        var _report$reportClass, _report$reportClass$m;

        const metricMatch = (_report$reportClass = report.reportClass) === null || _report$reportClass === void 0 ? void 0 : (_report$reportClass$m = _report$reportClass.metricDefinitions) === null || _report$reportClass$m === void 0 ? void 0 : _report$reportClass$m.some(metric => metric.name.toLowerCase().includes(searchTerm.trim().toLowerCase()));
        const nameMatch = report.name.toLowerCase().includes(searchTerm.trim().toLowerCase());
        return metricMatch || nameMatch;
      });
    });
  }

  const sortedDomains = domains.sort((a, b) => {
    // Override displayOrder to show AI Pro domain card last if AI Pro churn state is enabled
    if (showAiProChurnState) {
      const aIsAIPro = a.domainId === AI_PRO_DOMAIN_ID;
      const bIsAIPro = b.domainId === AI_PRO_DOMAIN_ID;
      if (aIsAIPro && !bIsAIPro) return 1;
      if (!aIsAIPro && bIsAIPro) return -1;
    } // Default sorting by displayOrder


    return a.displayOrder - b.displayOrder;
  });
  return sortedDomains;
};