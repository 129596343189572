import { useLocaleConfig } from '@attentive/acore-utils';

import { MetricRevenueByCurrency } from './types';

interface SortedMetricRevenueByCurrenciesProps {
  revenueByCurrencies: MetricRevenueByCurrency[];
}

export const useSortedMetricRevenueByCurrencies = ({
  revenueByCurrencies,
}: SortedMetricRevenueByCurrenciesProps) => {
  const localeConfig = useLocaleConfig();
  if (revenueByCurrencies.length === 0) {
    return [];
  } else if (revenueByCurrencies.length === 1) {
    return revenueByCurrencies;
  }
  const companyDefaultCurrencyRevenue = revenueByCurrencies.find(
    (revenueByCurrency) => revenueByCurrency.currency === localeConfig.currencyConfig.currencyCode
  );
  return companyDefaultCurrencyRevenue
    ? [
        companyDefaultCurrencyRevenue,
        ...revenueByCurrencies.filter(
          (revenueByCurrency) =>
            revenueByCurrency.currency !== companyDefaultCurrencyRevenue.currency
        ),
      ]
    : revenueByCurrencies;
};
