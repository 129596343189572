/**
 * @generated SignedSource<<5ab45044da06d46ed110b3a9b27d0611>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMetricRevenueByCurrencies_company$data = {
  readonly metricRevenueByCurrencies: ReadonlyArray<{
    readonly groupedValues: ReadonlyArray<{
      readonly groupingDimensions: ReadonlyArray<{
        readonly value: string;
      }>;
      readonly value: number | null;
    }>;
  }> | null;
  readonly " $fragmentType": "useMetricRevenueByCurrencies_company";
};
export type useMetricRevenueByCurrencies_company$key = {
  readonly " $data"?: useMetricRevenueByCurrencies_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMetricRevenueByCurrencies_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesGroupings"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "revenueByCurrenciesMetricId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMetricRevenueByCurrencies_company",
  "selections": [
    {
      "alias": "metricRevenueByCurrencies",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "revenueByCurrenciesFilters"
        },
        {
          "kind": "Variable",
          "name": "groupings",
          "variableName": "revenueByCurrenciesGroupings"
        },
        {
          "items": [
            {
              "kind": "Variable",
              "name": "metricIds.0",
              "variableName": "revenueByCurrenciesMetricId"
            }
          ],
          "kind": "ListValue",
          "name": "metricIds"
        }
      ],
      "concreteType": "Metric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupedMetricValue",
          "kind": "LinkedField",
          "name": "groupedValues",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DimensionValue",
              "kind": "LinkedField",
              "name": "groupingDimensions",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "2fbb2615808966a4effff99410ba61f7";

export default node;
