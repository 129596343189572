import React, { ReactNode } from 'react';

import {
  Box,
  Card,
  compositeComponent,
  Grid,
  Heading,
  Link,
  PicnicCss,
  Separator,
  Stack,
  Text,
} from '@attentive/picnic';

const MetricsDetailCard = ({ css, children }: { css?: PicnicCss; children: ReactNode }) => {
  return (
    <Card
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        p: '$space4',
        ...css,
      }}
    >
      <Stack
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Stack>
    </Card>
  );
};

const Header = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return (
    <Stack spacing="$space1" css={{ width: '100%' }}>
      <Heading variant="sm">{title}</Heading>
      {subtitle && <Text variant="caption">{subtitle}</Text>}
    </Stack>
  );
};

const Label = ({ children, css }: { children: ReactNode; css?: PicnicCss }) => {
  return (
    <Grid.Cell colSpan={3} css={{ whiteSpace: 'nowrap', ...css }}>
      <Heading variant="subheading">{children}</Heading>
    </Grid.Cell>
  );
};

const Content = ({ children }: { children: ReactNode }) => {
  return (
    <Box css={{ flex: 1, m: '$space0', width: '100%' }}>
      <Separator css={{ width: '100%', mt: '$space3', mb: '$space3' }} />
      <Grid
        columns={3}
        css={{
          display: 'grid',
          gridTemplateColumns: '3fr 1fr 1fr',
          columnGap: '$space4',
          width: '100%',
          p: '$space0',
          m: '$space0',
        }}
      >
        {children}
      </Grid>
    </Box>
  );
};

const Group = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      css={{
        display: 'contents',
        '& + &': {
          '> :first-child': {
            pt: '$space3',
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

const Row = ({ label, value, lift }: { label: string; value: ReactNode; lift?: ReactNode }) => {
  if (lift) {
    return (
      <>
        <Grid.Cell css={{ flex: 1 }}>
          <Text variant="caption">{label}</Text>
        </Grid.Cell>
        <Grid.Cell>
          <Text variant="caption" css={{ fontWeight: '$bold', textAlign: 'end' }}>
            {value}
          </Text>
        </Grid.Cell>
        <Grid.Cell>
          <Text variant="caption" color="success" css={{ fontWeight: '$bold', textAlign: 'end' }}>
            {lift}
          </Text>
        </Grid.Cell>
      </>
    );
  }

  return (
    <>
      <Grid.Cell css={{ flex: 1 }}>
        <Text variant="caption">{label}</Text>
      </Grid.Cell>
      <Grid.Cell>
        <Box />
      </Grid.Cell>
      <Grid.Cell>
        <Text variant="caption" css={{ fontWeight: '$bold', textAlign: 'end' }}>
          {value}
        </Text>
      </Grid.Cell>
    </>
  );
};

const PlaceholderRow = () => (
  <>
    <Grid.Cell css={{ flex: 1 }}>
      <Text variant="caption">&nbsp;</Text>
    </Grid.Cell>
    <Grid.Cell>
      <Text variant="caption" css={{ fontWeight: '$bold', textAlign: 'end' }}>
        &nbsp;
      </Text>
    </Grid.Cell>
    <Grid.Cell>
      <Text variant="caption" color="success" css={{ fontWeight: '$bold', textAlign: 'end' }}>
        &nbsp;
      </Text>
    </Grid.Cell>
  </>
);

const Footer = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Separator css={{ width: '100%', mt: '$space3', mb: '$space3' }} />
      <Stack css={{ marginTop: 'auto', width: '100%' }}>
        <Box css={{ alignSelf: 'flex-start' }}>{children}</Box>
      </Stack>
    </>
  );
};

const FooterLink = ({
  href,
  text,
  onClick,
}: {
  href: string;
  text: string;
  onClick?: () => void;
}) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      css={{
        cursor: 'pointer',
        fontWeight: '$bold',
        fontSize: '$fontSize2',
      }}
    >
      {text}
    </Link>
  );
};

const MetricsDetailCardNamespace = compositeComponent(MetricsDetailCard, {
  Header,
  Group,
  Content,
  Label,
  Row,
  PlaceholderRow,
  Footer,
  Link: FooterLink,
});

export { MetricsDetailCardNamespace as MetricsDetailCard };
