/**
 * @generated SignedSource<<45d89bf60b9a84f95ec469903b5ebff0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShouldHideAiJourneysCost_ReportingPlatformLib_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useHasAIJourneysAccessFragment_query">;
  readonly " $fragmentType": "useShouldHideAiJourneysCost_ReportingPlatformLib_query";
};
export type useShouldHideAiJourneysCost_ReportingPlatformLib_query$key = {
  readonly " $data"?: useShouldHideAiJourneysCost_ReportingPlatformLib_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldHideAiJourneysCost_ReportingPlatformLib_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShouldHideAiJourneysCost_ReportingPlatformLib_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useHasAIJourneysAccessFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f15468e17cea7838f459ba041d723143";

export default node;
