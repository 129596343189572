/**
 * @generated SignedSource<<91ec47b07df83fb2649f80a92222b5d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_company">;
  readonly " $fragmentType": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company";
};
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$key = {
  readonly " $data"?: useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateReportMetricsColumnHeaders_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "7e33fed6d848d51b9982eeeb8a36d86b";

export default node;
