/**
 * @generated SignedSource<<aa8d3fb44c2252b1c431e724b9bc34fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAiProRevenueData_ReportingPlatform_company$data = {
  readonly aiProReducedCostMetrics?: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly audiencesAiExpansionBaseRevenueMetrics?: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly audiencesAiIncrementalRevenueMetrics?: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly identityAiBaseRevenueMetrics: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly identityAiIncrementalRevenueMetrics: ReadonlyArray<{
    readonly aggregateValue: number | null;
  }> | null;
  readonly " $fragmentType": "useAiProRevenueData_ReportingPlatform_company";
};
export type useAiProRevenueData_ReportingPlatform_company$key = {
  readonly " $data"?: useAiProRevenueData_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAiProRevenueData_ReportingPlatform_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "values": [
    "true"
  ]
},
v1 = {
  "dimensionId": "has_audience_ai",
  "list": (v0/*: any*/),
  "operator": "FILTER_OPERATOR_IN"
},
v2 = {
  "kind": "Literal",
  "name": "metricIds",
  "value": [
    "campaign_revenue"
  ]
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "aggregateValue",
    "storageKey": null
  }
],
v4 = {
  "kind": "Variable",
  "name": "filters.1",
  "variableName": "metricsDateFilter"
},
v5 = {
  "kind": "Variable",
  "name": "filters.2",
  "variableName": "regionFilter"
},
v6 = {
  "kind": "Variable",
  "name": "filters.3",
  "variableName": "journeyTypeFilter"
},
v7 = {
  "kind": "Literal",
  "name": "metricIds",
  "value": [
    "identity_ai_revenue"
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isEmailOnlyEnabled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "journeyTypeFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "metricsDateFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "regionFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAiProRevenueData_ReportingPlatform_company",
  "selections": [
    {
      "condition": "isEmailOnlyEnabled",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "audiencesAiIncrementalRevenueMetrics",
          "args": [
            {
              "items": [
                {
                  "kind": "Literal",
                  "name": "filters.0",
                  "value": (v1/*: any*/)
                },
                {
                  "kind": "Literal",
                  "name": "filters.1",
                  "value": {
                    "dimensionId": "audience_ai_segment_group",
                    "list": {
                      "values": [
                        "Expansion"
                      ]
                    },
                    "operator": "FILTER_OPERATOR_IN"
                  }
                },
                {
                  "kind": "Variable",
                  "name": "filters.2",
                  "variableName": "metricsDateFilter"
                },
                {
                  "kind": "Variable",
                  "name": "filters.3",
                  "variableName": "regionFilter"
                }
              ],
              "kind": "ListValue",
              "name": "filters"
            },
            (v2/*: any*/)
          ],
          "concreteType": "Metric",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": "audiencesAiExpansionBaseRevenueMetrics",
          "args": [
            {
              "items": [
                {
                  "kind": "Literal",
                  "name": "filters.0",
                  "value": {
                    "dimensionId": "audience_ai_scenario",
                    "list": {
                      "values": [
                        "Expansion only",
                        "Expansion + low propensity removed",
                        "Expansion + low propensity tracked"
                      ]
                    },
                    "operator": "FILTER_OPERATOR_IN"
                  }
                },
                {
                  "kind": "Literal",
                  "name": "filters.1",
                  "value": (v1/*: any*/)
                },
                {
                  "kind": "Literal",
                  "name": "filters.2",
                  "value": {
                    "dimensionId": "audience_ai_segment_group",
                    "list": {
                      "values": [
                        "Base"
                      ]
                    },
                    "operator": "FILTER_OPERATOR_IN"
                  }
                },
                {
                  "kind": "Variable",
                  "name": "filters.3",
                  "variableName": "metricsDateFilter"
                },
                {
                  "kind": "Variable",
                  "name": "filters.4",
                  "variableName": "regionFilter"
                }
              ],
              "kind": "ListValue",
              "name": "filters"
            },
            (v2/*: any*/)
          ],
          "concreteType": "Metric",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": "aiProReducedCostMetrics",
          "args": [
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "filters.0",
                  "variableName": "metricsDateFilter"
                },
                {
                  "kind": "Variable",
                  "name": "filters.1",
                  "variableName": "regionFilter"
                }
              ],
              "kind": "ListValue",
              "name": "filters"
            },
            {
              "kind": "Literal",
              "name": "metricIds",
              "value": [
                "campaign_suppressed_send_savings"
              ]
            }
          ],
          "concreteType": "Metric",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "alias": "identityAiIncrementalRevenueMetrics",
      "args": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "dimensionId": "is_identity_ai",
                "list": (v0/*: any*/),
                "operator": "FILTER_OPERATOR_IN"
              }
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        },
        (v7/*: any*/)
      ],
      "concreteType": "Metric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "identityAiBaseRevenueMetrics",
      "args": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "dimensionId": "is_identity_ai",
                "list": {
                  "values": [
                    "false"
                  ]
                },
                "operator": "FILTER_OPERATOR_IN"
              }
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        },
        (v7/*: any*/)
      ],
      "concreteType": "Metric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "122c76d9dcf630bcc91f7e736b69cb44";

export default node;
