import _useMetricRevenueByCurrencies_company from "./__generated__/useMetricRevenueByCurrencies_company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { logError } from '../../../utils';
import useMetricRevenueByCurrencies_company from './__generated__/useMetricRevenueByCurrencies_company.graphql';
_useMetricRevenueByCurrencies_company;
export const useMetricRevenueByCurrencies = _ref => {
  let {
    companyRef
  } = _ref;
  const {
    metricRevenueByCurrencies
  } = useFragment(useMetricRevenueByCurrencies_company, companyRef);

  if (!metricRevenueByCurrencies || !metricRevenueByCurrencies[0]) {
    logError(new Error('Failed to load revenue by currency'));
    return [];
  }

  const {
    groupedValues
  } = metricRevenueByCurrencies[0];
  return groupedValues.map(_ref2 => {
    let {
      groupingDimensions,
      value
    } = _ref2;
    return {
      grouping: groupingDimensions.map(_ref3 => {
        let {
          value: grouping
        } = _ref3;
        return grouping;
      }),
      revenue: value || 0
    };
  });
};