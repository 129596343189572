import _useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query from "./__generated__/useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useHasAIJourneysAccess } from '@attentive/ml-common';
import useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query from './__generated__/useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query.graphql';
_useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query;
export const useHasAIJourneysPerformanceReportAccess = queryRef => {
  const queryData = useFragment(useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query, queryRef);
  const [hasAIJourneysAccess] = useHasAIJourneysAccess(queryData); // backend flag for performance report

  const isAIJourneysPerformanceReportConfigEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_PERFORMANCE_REPORT');
  const hasAIJourneysPerformanceReportAccess = isAIJourneysPerformanceReportConfigEnabled && hasAIJourneysAccess;
  return {
    hasAIJourneysPerformanceReportAccess
  };
};